import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import UpNext from "../components/UpNext";
import SEO from "../components/SEO";

// eslint-disable-next-line
const IndexPage = (props) => {
    //console.log(props);
    const pageHeading = props.data.markdownRemark.frontmatter.pageheading;
    const pageSubheading = props.data.markdownRemark.frontmatter.pagesubheading;
    const pageSubheadingLink = props.data.markdownRemark.frontmatter.pagesubheadinglink;
    const pageSubheadingPre = props.data.markdownRemark.frontmatter.pagesubheadingpre;
  return (
    <React.Fragment>
      <SEO {...props.data.seo} />
      <section className="section landing-page-section is-flex is-align-items-center" >
        <div className="container is-flex is-flex-direction-column is-justify-content-center">
          <div className="radial-background"></div>
          <div className="header-container is-flex is-flex-direction-column">
            <h1 className="title has-text-centered">{pageHeading}</h1>
            <div className="thesis-link">
              <UpNext url={pageSubheadingLink} text={pageSubheadingPre + ' ' + pageSubheading} />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

IndexPage.propTypes = {
  pageheading: PropTypes.string,
};

export default IndexPage;

export const indexQuery = graphql`
  query IndexQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        pageheading,
        pagesubheading,
        pagesubheadinglink,
        pagesubheadingpre,
      }
    }
    seo: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      ...SEOFragment
    }
  }
`;
